// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-stories-template-stories-template-js": () => import("./../../../src/components/StoriesTemplate/StoriesTemplate.js" /* webpackChunkName: "component---src-components-stories-template-stories-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-esg-index-js": () => import("./../../../src/pages/esg/index.js" /* webpackChunkName: "component---src-pages-esg-index-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsroom-contact-js": () => import("./../../../src/pages/newsroom/contact.js" /* webpackChunkName: "component---src-pages-newsroom-contact-js" */),
  "component---src-pages-newsroom-index-js": () => import("./../../../src/pages/newsroom/index.js" /* webpackChunkName: "component---src-pages-newsroom-index-js" */),
  "component---src-pages-newsroom-press-material-brand-js": () => import("./../../../src/pages/newsroom/press-material/brand.js" /* webpackChunkName: "component---src-pages-newsroom-press-material-brand-js" */),
  "component---src-pages-newsroom-press-material-factsheet-js": () => import("./../../../src/pages/newsroom/press-material/factsheet.js" /* webpackChunkName: "component---src-pages-newsroom-press-material-factsheet-js" */),
  "component---src-pages-newsroom-press-material-js": () => import("./../../../src/pages/newsroom/press-material.js" /* webpackChunkName: "component---src-pages-newsroom-press-material-js" */),
  "component---src-pages-newsroom-press-material-management-board-js": () => import("./../../../src/pages/newsroom/press-material/management-board.js" /* webpackChunkName: "component---src-pages-newsroom-press-material-management-board-js" */),
  "component---src-pages-newsroom-press-material-products-js": () => import("./../../../src/pages/newsroom/press-material/products.js" /* webpackChunkName: "component---src-pages-newsroom-press-material-products-js" */),
  "component---src-pages-newsroom-press-material-supply-chain-js": () => import("./../../../src/pages/newsroom/press-material/supply-chain.js" /* webpackChunkName: "component---src-pages-newsroom-press-material-supply-chain-js" */),
  "component---src-pages-newsroom-press-releases-js": () => import("./../../../src/pages/newsroom/press-releases.js" /* webpackChunkName: "component---src-pages-newsroom-press-releases-js" */),
  "component---src-pages-newsroom-stories-js": () => import("./../../../src/pages/newsroom/stories.js" /* webpackChunkName: "component---src-pages-newsroom-stories-js" */)
}

